<template>
  <div class="px-40 border border-EEEEEE py-35 purseBox">
    <!-- 标签 -->
    <div class="flex align-center justify-between pb-20 border-bottom border-F4F4F4">
      <div class="font-20 text-333333">套餐明细</div>
      <div class="font-12 text-666666 flex align-center">
        目前所在：<el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/myIndex' }">个人中心</el-breadcrumb-item>
          <el-breadcrumb-item>套餐明细</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!-- 套餐说明和购买套餐 -->
    <div class="mt-20 flex align-center justify-between">
      <div></div>
      <div class="flex align-center">
        <div class="_btn1" @click="xeiyiflag = true">套餐说明</div>
        <div class="_btn2" @click="gotopUp">购买套餐</div>
      </div>
    </div>
    <!-- 内容 -->
    <div class="py-35 purseBox_cont">
      <el-table :data="list" style="width: 100%" height="250">
        <el-table-column label="套餐名" prop="package_name"> </el-table-column>
        <el-table-column prop="pay_time" label="购买时间"> </el-table-column>
        <el-table-column prop="package_day" label="有效期">
          <template slot-scope="scope">
            <div>{{ scope.row.package_day }}天</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="flex align-center justify-center py-50 border-top border-ligit-BFBFBF">
      <el-pagination
        background
        layout="prev, pager, next"
        prev-text="上一页"
        next-text="下一页"
        :page-count="page_nums"
        @current-change="currentChange"
        :current-page="page"
      >
      </el-pagination>
    </div>
    <el-dialog title="会员服务协议" :visible.sync="xeiyiflag" width="35%">
      <div class="" style="height: 31.25rem; overflow-y: auto">
        <div v-html="info.package_content"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import req from "../../../utils/req";
export default {
  data() {
    return {
      xeiyiflag: false,
      list: [],
      page: 1,
      page_nums: 0,
      info:"",
    };
  },
  mounted() {
    this.getdata();
    this.getVip()
  },
  methods: {
    getVip() {
      let that = this;
      req
        .post("center/vipPackage", {})
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          res.data.package_content = res.data.package_content
            .replace(/<img/gi, '<img style="max-width:100%;height:auto;display:block" ')
            .replace(/<section/g, "<div")
            .replace(/\/section>/g, "div>");
          that.info = res.data;
        })
        .catch((err) => {
          that.$message.error(err.msg);
        });
    },
    // 跳转充值
    gotopUp() {
      this.$router.push({
        path: "/topUp",
        query: {type: "topUp",toptype: 2},
      });
    },
    currentChange(e) {
      console.log(e);
      this.page = e;
      this.getdata();
    },
    // 获取数据
    getdata() {
      let that = this;
      req
        .post("center/packageLog", {
          page: that.page,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          that.list = res.data.data;
          that.page_nums = res.data.last_page;
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  ._btn1 {
    width: 5.8125rem;
    height: 2.1875rem;
    background: linear-gradient(-90deg, #0f7bad, #0e58bd);
    box-shadow: 0 0.25rem 0.4375rem 0 rgba(17, 110, 169, 0.18);
    border-radius: 1.125rem;
    color: #ffffff;
    font-size: 0.875rem;
    line-height: 2.1875rem;
    text-align: center;
    margin-right: 1.875rem;
    cursor: pointer;
  }
  ._btn2 {
    width: 5.8125rem;
    height: 2.1875rem;
    background: linear-gradient(-90deg, #fe9309, #fe5509);
    box-shadow: 0 0.25rem 0.4375rem 0 rgba(254, 85, 9, 0.18);
    border-radius: 1.125rem;
    color: #ffffff;
    font-size: 0.875rem;
    line-height: 2.1875rem;
    text-align: center;
    cursor: pointer;
  }
.purseBox {
  height: 100%;
  box-sizing: border-box;
  &_cont {
    ::v-deep .el-table--fit {
      border-right: 1px solid #ebeef5;
      border-left: 1px solid #ebeef5;
      border-top: 1px solid #ebeef5;
    }
  }
}
</style>
